import React from 'react';
import Helmet from 'react-helmet';

import Section from '$ui/section';
import Heading from '$ui/heading';

const VideoPage = () => {
  return (
    <>
      <Section className='pt-32'>
        <Helmet>
          <title>TheCRO &mdash; Videos</title>
        </Helmet>

        <Heading id='videos'>Videos</Heading>
        <div className='grid grid-cols-3 mb-5 gap-10  ml-10 mt-10 tab-port:px-8'>
          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/qQfbrQb5kgI'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen></iframe>

          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/NmpcPzJjwP0'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen></iframe>

          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/ieu3epaSt9o'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen></iframe>

          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/MmM4GHo'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen></iframe>

          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/Z_xgvJM5rNs'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen></iframe>

          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/sOTHTtx74EM'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen></iframe>
        </div>
      </Section>
    </>
  );
};

export default VideoPage;
